<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter"
      style="height: 90vh">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tableJournals
        :dataTable="orders"
        @selectOrder="selectOrder"
        @itemData="itemData"
        @openHandle="openHandle"
        @openHistoryModal="openHistoryModal"
        @handleContextMenuEvent="handleContextMenuEvent"
        @getOrderByIds="getOrderByIds"
        @getOrderById="getOrderById"
        @showOnTheMap="showOnTheMap"
        @getOrderByIdShow="getOrderByIdShow"
        @getPath="getPath"
        :field="field" />
    </div>
    <modal-order-create
      :orderData="orderData"
      @refresh="refresh"
      v-hotkey="{ insert: showCreateOrder }"
      @GetOrder="getOrder" />
    <modal-order-handle :orderData="orderData" @refresh="refresh" :editOrder="editOrder" :orderId="orderId" />
    <modal-order-edit :orderData="orderData" @refresh="refresh" :editOrder="editOrder" />
    <mail-modal></mail-modal>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    <modal-order-complaint @refresh="refresh" :editOrder="editOrder"></modal-order-complaint>
    <modal-order-history :orderId="orderId"></modal-order-history>
    <modal-send-message :clientPhone="clientPhone"></modal-send-message>
    <modal-for-driver-message :perfomerPhone="perfomerPhone"></modal-for-driver-message>
    <modal-order-show :orderData="orderData" @refresh="refresh" :editOrder="editOrder"></modal-order-show>
  </div>
</template>

<script>
import ModalForDriverMessage from "@/views/component/Modal/ModalForDriverMessage/MessageForDriver.vue";
import ModalOrderShow from "@/views/component/Modal/ModalOrder/ModalOrderShow.vue";
import ModalOrderCreate from "@/views/component/Modal//ModalOrder/ModalOrderCreate.vue";
import ModalOrderHandle from "@/views/component/Modal//ModalOrder/ModalOrderHandle.vue";
import ModalOrderEdit from "@/views/component/Modal//ModalOrder/ModalOrderEdit.vue";
import ModalOrderComplaint from "@/views/component/Modal/ModalOrder/ModalOrderСomplaint.vue";
import ModalOrderHistory from "@/views/component/Modal/ModalOrder/ModalOrderHistory.vue";
import MailModal from "@/views/component/Modal/ModalOrder/MailModal.vue";
import ModalSendMessage from "@/views/component/Modal/ModalMessage/ModalSendMessage.vue";
import tableJournals from "@/views/component/Table/tableJournals.vue";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    ModalOrderShow,
    ModalForDriverMessage,
    ModalSendMessage,
    ModalOrderCreate,
    ModalOrderHandle,
    ModalOrderComplaint,
    ModalOrderEdit,
    ModalOrderHistory,
    tableJournals,
    MailModal,
  },
  data() {
    return {
      data: [],
      clientPhone: null,
      perfomerPhone: null,
      orderData: "",
      positionId: JSON.parse(localStorage.getItem("userData")).role_id,
      memos_fields: [
        {
          key: "checkbox",
          label: "",
          thStyle: { width: "30px" },
        },
        {
          key: "id",
          label: "Id",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "comment",
          label: "Комментарий",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "memo_subject.name",
          label: "Тема записки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "memo_type.name",
          label: "Тип записки",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id" },
        { key: "division", label: "Подразделения" },
        { key: "phone", label: "Номер телефона" },
        { key: "from_address.name", label: "Откуда" },
        { key: "to_addresses", label: "Куда" },
        { key: "last_name", label: "Ф.И.О" },
        { key: "performer_login", label: "Логин" },
        { key: "price", label: "Цена" },
        { key: "payment_type", label: "Тип оплаты" },
        { key: "car_info", label: "Автомобиль" },
        { key: "status", label: "Статус" },
        { key: "type", label: "Тип" },
        { key: "tariff", label: "Тариф" },
        { key: "created_by", label: "Создал" },
        { key: "info_for_operator", label: "Доп. инфо для оператора" },
        { key: "info_for_driver", label: "Доп. и. в." },
        { key: "client_status", label: "Сатус клиента" },
        { key: "perfomer_phone", label: "Номер водителя" },
        { key: "assignment_by", label: "Назначил" },
        { key: "dop_phone", label: "Доп. номер" },
        { key: "date_time", label: "Время поступления" },
        { key: "commission", label: "Комиссия" },
      ],
      field: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "from_address.title",
          label: "Откуда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "from_address.address",
          label: "Откуда (полный адрес)",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "to_addresses[0].title",
          label: "Куда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "to_addresses[0].address",
          label: "Куда (полный адрес)",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "price",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "payment_type",
          label: "Тип оплаты",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "bonus_price",
          label: "Безнал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_phone",
          label: "Доп. номер",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "Запросы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "past_minute",
          label: "ВП",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "tariff",
          label: "Тариф",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Время поступления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_time",
          label: "На время",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "price_by_performer",
          label: "Цена(Водитель)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "price_by_client",
          label: "Цена(Клиент)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "updated_at",
          label: "Время назначения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "start_time",
          label: "Начало поездки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "downtime",
          label: "Время простоя ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "end_time",
          label: "Время окончания",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "assignmentBy",
          label: "Назначил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "info_for_drivers",
          label: "Доп. инфо для водителя",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "info_for_operator",
          label: "Доп. инфо для оператора",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "create_user.login",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_phone",
          label: "Номер водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "client_status",
          label: "Статус клиента",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "reason_cancel_order",
          label: "Причина отмены",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "allowances",
          label: "Надбавки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "distance",
          label: "Расстояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history",
          label: "История изминения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history_call",
          label: "История звонков",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "commission_price",
          label: "Комиссия",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      items: [],
      orders: [],
      showPreloader: false,
      search_Phone: "",
      geo_location: null,
      latest_order: [],
      all_search_orders: [],
      editOrder: {
        division_id: "1",
        phone: "",
        dop_phone: undefined,
        auto_assignment: 1,
        not_issued: 0,
        for_time: 0,
        date_time: undefined,
        search_address_id: null,
        to_addresses: [
          {
            search_address_id: undefined,
          },
        ],
        meeting_info: undefined,
        info_for_operator: "",
        info_for_drivers: "",
        order_type_id: "1",
        tariff_id: "1",
        price: 0,
        distance: 0.0,
        number_of_passengers: undefined,
        allowances: [],
      },
      orderId: "",
      memos: [],
      refreshTimer: null,
    };
  },
  methods: {
    getOrderByPhone() {
      this.$http.get(`orders/order-by-phone?phone=${this.search_Phone}`).then((res) => {
        this.latest_order = res.data.latest_order;
        this.all_search_orders = res.data.orders;
        this.memos = res.data.memos;
      });
    },
    getOrderData() {
      this.$http.get("orders/data").then((res) => {
        this.orderData = res.data;
      });
    },
    city(e, city) {
      var button = document.getElementsByClassName("but"),
        tabContent = document.getElementsByClassName("tab-content");
      var i;
      for (i = 0; i < button.length; i++) {
        tabContent[i].style.display = "none";
        button[i].classList.remove("btn-primary");
      }
      let cityBlock = document.getElementById(city);
      cityBlock.style.display = "block";
      e.currentTarget.classList.add("btn-primary");
    },
    sendToParent(tableData) {
      this.orders = tableData;
      this.$store.commit("pageData/setdataCount", this.orders.length);
      this.$store.commit("excel/SET_ORDERS", this.orders);
      this.$store.commit("excel/SET_COLUMNS", this.field);
    },
    filteredOrders(orders) {
      this.orders = orders;
      this.$store.commit("pageData/setdataCount", this.orders.length);
    },
    getOrder(orders) {
      this.orders = orders;
    },
    showCreateOrder() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    handleContextMenuEvent(item) {
      this.geo_location = item.geo_json_array;
      this.data = item;
      this.clientPhone = item.phone;
      if (item.performer) {
        this.perfomerPhone = item.performer.phone;
      }
      this.orderId = item.id;
    },
    selectOrder(item) {
      this.$http.get(`orders/${item.id}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("edit_order");
      });
    },
    openHandle() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("handle_order");
      });
    },
    openHistoryModal() {
      if (this.positionId == 1) {
        this.$bvModal.show("order_history");
      } else {
        this.getPath();
      }
    },
    getOrderByIds() {
      this.$bvModal.show("handle_order");
    },
    getOrderById() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("edit_order");
      });
    },
    getOrderByIdShow() {
      this.$http.get(`orders/${this.orderId}/edit`).then((res) => {
        this.editOrder = res.data;
        this.$bvModal.show("show_order");
      });
    },
    getHistory() {
      this.showPreloader = true;
      this.$http
        .get(`orders/${this.orderId}/histories`)
        .then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.showPreloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrder(orders) {
      this.orders = orders;
      this.$store.commit("pageData/setdataCount", this.orders.length);
    },
    itemData(item) {
      this.data = item;
      this.orderId = item.id;
    },
    refresh() {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let access = JSON.parse(localStorage.getItem("userData"));
      if (access.role_id == 2) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.showPreloader = true;
          setTimeout(() => {
            this.orders = [];
            this.showPreloader = false;
            this.orders = res.data;
            this.$store.commit("pageData/setdataCount", this.orders.length);
          }, "200");
          this.$store.commit("excel/SET_ORDERS", this.orders);
          this.$store.commit("excel/SET_COLUMNS", this.field);
          this.$store.commit("REFRESH_DATA", false);
        });
      } else {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.orders = [];
          this.$store.commit("excel/SET_ORDERS", this.orders);
          this.$store.commit("excel/SET_COLUMNS", this.field);
          this.showPreloader = true;
          setTimeout(() => {
            this.orders = res.data;
            this.showPreloader = false;
            this.$store.commit("pageData/setdataCount", this.orders.length);
          }, "200");
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
    getPath() {
      this.$router.push({
        name: "orderHistory",
        query: { filter: this.orderId },
      });
      setTimeout(() => {
        this.$store.commit("draggableTab/ADD_TAB", ["История " + this.orderId, `/order/order-history`, this.orderId]);
      }, 200);
    },
    showOnTheMap() {
      let data = this.data;
      this.$router.push({ name: "showOnThe-map", query: { map: data.id } });
      this.$store.commit("draggableTab/ADD_TAB", [data.id, `/showOnThe-map`, data.id]);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.orders = [];
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
          this.$store.commit("excel/SET_ORDERS", this.orders);
          this.$store.commit("excel/SET_COLUMNS", this.field);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
  },

  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.getOrderData();
    this.openFilter();
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
    this.$store.commit("excel/SET_ORDERS", []);
    this.$store.commit("excel/SET_COLUMNS", []);
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.orders = [];
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.orders = res.data;
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
