<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0" @scroll.passive="handleScroll">
          <b-table
            striped
            small
            id="myTable"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="displayedData"
            :fields="field"
            head-row-variant="secondary"
            :tbody-tr-class="rowClass"
            @row-dblclicked="selectOrder"
            @row-clicked="clicked"
            v-hotkey="{ F2: openHandle, F11: openHistoryModal }"
            @row-contextmenu="handleContextMenuEvent"
            @row-hovered="hovering"
            @row-unhovered="unhovering">
            <template #cell(checkbox)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
              </b-form-checkbox>
            </template>
            <template #cell(create_user)="data">
              {{ data.item.create_user ? data.item.create_user.login : "" }}3
            </template>
            <template #cell(performer_name)="data">
              {{
                data.item.performer == null ? "" : data.item.performer.last_name + " " + data.item.performer.first_name
              }}
            </template>
            <template #cell(performer_phone)="data">
              {{ data.item.performer ? data.item.performer.phone : "" }}
            </template>
            <template #cell(performer_login)="data">
              <span @dblclick="getLogin(data)" v-if="data.item.performer" class="reference-field">
                {{ data.item.performer ? data.item.performer.login : "" }}
              </span>
            </template>
            <template #cell(performer_transport)="data">
              {{
                data.item.performer == null || data.item.performer.transport == null
                  ? ""
                  : data.item.performer.transport.model +
                    ` ${data.item.performer.transport.color}` +
                    ` ${data.item.performer.transport.car_number}`
              }}
            </template>
            <template #cell(distance)="data">
              {{ data.item.distance != null ? data.item.distance.toFixed(1) + "км" : "" }}
            </template>
            <template #cell(allowances)="data">
              <span v-for="allowance in data.item.allowances" :key="allowance.id">
                {{ allowance.name + "; " }}
              </span>
            </template>
            <template #cell(report)="data">
              <span @dblclick="getAssigment(data)" class="reference-field"> (***) </span>
            </template>
            <template #cell(history)="data">
              <span @dblclick="getPathData(data)" class="reference-field"> (***) </span>
            </template>
            <template #cell(history_call)="data">
              <span @dblclick="getHistoryCall(data)" class="reference-field"> (***) </span>
            </template>
            <template #cell(price_by_client)="data">
              {{ data.item.price - data.item.bonus_price }}
            </template>
            <template #cell(downtime)="data">
              {{ calculateDowntime(data.item) }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link class="d-flex align-items-center" @click="getOrderByIds()">
                <feather-icon icon="EditIcon" size="16" />
                <span class="ml-75">Обработать</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="showOnTheMap()" v-hotkey="{ 'ctrl+m': showOnTheMap }">
                <feather-icon icon="NavigationIcon" size="16" />
                <span class="ml-75">Открыть на карте</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.messageCreateModal>
                <feather-icon icon="MailIcon" />
                <span class="ml-75">Отправить СМС</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.messageCreateModalDriver>
                <feather-icon icon="MailIcon" />
                <span class="ml-75">Отправить СМС водителю </span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="getOrderById()">
                <feather-icon icon="Edit2Icon" size="16" />
                <span class="ml-75">Изменить</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="getOrderByIdShow()">
                <feather-icon icon="EyeIcon" size="16" />
                <span class="ml-75">Просмотр</span>
              </b-link>
              <b-link v-if="positionId == 1" class="d-flex align-items-center" v-b-modal.order_history>
                <feather-icon icon="BookOpenIcon" size="16" />
                <span class="ml-75">История изменения</span>
              </b-link>
              <div
                v-else
                style="padding: 0.65rem 1.28rem; cursor: pointer"
                class="d-flex align-items-center"
                @click="getPath()">
                <feather-icon icon="BookOpenIcon" size="16" />
                <span class="ml-75">История изменения</span>
              </div>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import VueContext from "vue-context";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
import moment from "moment";
export default {
  mixins: [lazyLoading],
  components: {
    VueContext,
  },
  props: ["dataTable", "field"],
  data() {
    return {
      positionId: JSON.parse(localStorage.getItem("userData")).role_id,
    };
  },
  methods: {
    moment,
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status_id === 10) return "table-danger";
      else if (item.status_id === 11) return "table-warning";
      else if (item.status_id === 9) return "table-success";
      else if (item.status_id === 1) return "";
      else return "table-secondary";
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

      if (columnIndex < 0 || columnIndex >= this.field.length) {
        return;
      }

      const clickedField = this.field[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      this.$emit("itemData", item);
      cellSelect();
    },
    selectOrder(item) {
      this.$emit("selectOrder", item);
    },
    openHandle() {
      this.$emit("openHandle");
    },
    openHistoryModal() {
      this.$emit("openHistoryModal");
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.$emit("handleContextMenuEvent", item);
    },
    hovering(item, index, event) {
      event.target.style.backgroundColor = "#B8D2FF";
    },
    calculateDowntime(item) {
      let downTime = item.filing_time ? item.filing_time : item.updated_at;
      const filingTime = moment(downTime, "YYYY-MM-DD HH:mm:ss");
      const createdAt = moment(item.created_at, "YYYY-MM-DD HH:mm:ss");
      if (filingTime.isValid() && createdAt.isValid()) {
        return filingTime.diff(createdAt, "minutes") + " мин";
      }
      return "";
    },
    unhovering(item, index, event) {
      event.target.style.backgroundColor = "";
    },
    getLogin(data) {
      this.$router.push({
        name: "infoLogin",
        query: { filter: data.item.performer.login },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer.login,
        `/infoLogin`,
        data.item.performer.login,
      ]);
    },
    getAssigment(data) {
      this.$store.commit("draggableTab/ADD_TAB", ["Запросы " + data.item.id, `/order/assigment`, data.item.id]);
      this.$router.push({
        name: "report/assigment",
        query: { filter: data.item.id },
      });
    },
    getPathData(data) {
      this.$store.commit("draggableTab/ADD_TAB", ["История " + data.item.id, `/order/order-history`, data.item.id]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.id },
      });
    },
    getHistoryCall(data) {
      this.$router.push({
        name: "orderHistoryCall",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", ["Звонки " + data.item.id, `/order/history-call`, data.item.id]);
    },
    getOrderByIds() {
      this.$emit("getOrderByIds");
    },
    getOrderById() {
      this.$emit("getOrderById");
    },
    showOnTheMap() {
      this.$emit("showOnTheMap");
    },
    getOrderByIdShow() {
      this.$emit("getOrderByIdShow");
    },
    getPath() {
      this.$emit("getPath");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
